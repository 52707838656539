<section class="section banner">
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div>
                    <!-- <h1>{{ constants["BannerTitle"] }}</h1> -->
                    <h1>{{constants['Pretitle']}}</h1>
                </div>
                <div class="details">
                    <div>
                        <div class='banner-title'>
                            <h2>Gaurav Khatwani</h2>
                            <h3>Software Developer</h3>
                        </div>
                        <div class='banner-description'>
                            <p [innerHTML]='constants["Description"]' class="mt-4"></p>
                        </div>
                    </div>
                    <div class="animation">
                        <app-human-animation [animationName] = "'idle'" [size] = '3' [position]="[-2,-2.5,0]"></app-human-animation>
                    </div>
                </div>
            </div>
            <div class="div-btn-banner">
                <a href="mailto:gauravkhatwani148@gmail.com" target="_black" class="main-btn">
                    {{constants['ActionBtn']}}
                </a>
            </div>
        </div>
    </div>
</section>
