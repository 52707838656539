<section class="section jobs" id='jobs'>
    <div class="container">
      <div style="display: flex; justify-content: space-between; flex-direction: row;">
        <div class="section-box">
          <div class="about-title" data-aos="fade-up">
            <h3 class="section-title">
                <span class='n-section-title'>02.</span> {{constants["Title"]}}
            </h3>
        </div>
          <div class="jobs-tabs" data-aos="fade-up" data-aos-duration="1000">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" orientation="vertical" class="nav-tabs jobs-tabs">
                  <li *ngFor='let job of constants["Jobs"]; let i = index' [title]="job.Tab" [ngbNavItem]="i">
                    <a ngbNavLink>
                      <img style="width: 20px; height: 20px; margin-right: 10px;" src= {{job.Logo}} alt="">
                      {{job.Tab}}
                    </a>
                      <ng-template ngbNavContent>
                        <div class="job-description-box">
                          <div style="display: flex;">
                            <img style="width: 20px; height: 20px; margin-right: 10px;" src= {{job.Logo}} alt="">
                            <div>
                              <h4 class='title-tab-content'>{{job.Title}} | <span class="company-tab-content">{{job.Tab}}</span></h4>
                              <h5 class="job-time">{{job.Date}}</h5>
                            </div>
                          </div>
                          <p *ngFor='let jobDescriptionParagraph of job.Description; let i = index' class="job-description"
                          [innerHTML]="jobDescriptionParagraph"></p>
                        <span></span>
                        </div>          
                      </ng-template>
                  </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
        <div class="animation">
          <app-human-animation [animationName] = "'working'" [size] = '3' [position]="[-2,-2.5,0]"></app-human-animation>
        </div>
      </div>
    </div>
</section>
