<section class="section contact" id='contact'>
    <div class="container">
        <div style="display: flex; justify-content: space-evenly; flex-direction: row;">
            <div class="section-box">
                <div class="mb-4" data-aos="fade-up" data-aos-duration="1000">
                    <h3 class="contact-pre-title big-subtitle">05. {{constants["Pretitle"]}}</h3>
                    <h4 class="e-font contact-title big-title">
                        {{constants["Title"]}}
                    </h4>
                </div>
                <p data-aos="fade-up" data-aos-duration="1000">
                    {{constants["Content"]}}
                </p>
                <div class="mt-5">
                    <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "contact", "email")' href="mailto:gauravkhatwani148@gmail.com" target="_blank" class="main-btn" data-aos="fade-up" data-aos-duration="1000">
                        {{constants["Btn"]}}
                    </a>
                </div>
            </div>
            <div style="padding-top: 150px; padding-left: 100px;" class="animation">
                <app-human-animation [animationName] = "'dancing'" [size] = '3' [position]="[-2,-2.5,0]"></app-human-animation>
            </div>
        </div>
    </div>
</section>