<main>
    <!-- <div *ngFor="let item of ['Item1', 'Item2', 'Item3']">
        <app-animation></app-animation>
        {{ item }}
    </div> -->
    <app-banner></app-banner>
    <app-about></app-about>
    <app-jobs></app-jobs>
    <app-proyects></app-proyects>
    <app-more-proyects></app-more-proyects>
    <app-contact></app-contact>
</main>

