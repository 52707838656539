<footer>
    <div [@animateFooter]>
        <ul class="footer-left-bar d-none d-md-block">
            <li>
                <a href="https://github.com/gaurav148" target='_blank'>
                    <i class="fab fa-github"></i>
                </a>
            </li>
            <li>
                <a href="https://www.linkedin.com/in/gaurav-khatwani-7024991a9/" target='_blank'>
                    <i class="fab fa-linkedin-in"></i>
                </a>
            </li>
        </ul>
        <div class="footer-right-bar d-none d-md-block">
            <a href="mailto:gauravkhatwani148@gmail.com" target='_blank'>gauravkhatwani148@gmail.com </a>
        </div>
    </div>

    <div class="footer-credits text-center" >
        <a href="https://github.com/gaurav148/portfolio-v1" target="_blank" rel="nofollow noopener noreferrer">
            <div>Built with Angular by Gaurav Khatwani</div>
        </a>
    </div>

</footer>